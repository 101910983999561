.App {
  background-image: url('./components/nyancat.svg');
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
.center-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  background: none;
}
.jumbo {
  font-size: 7em;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000;
}